import React from 'react';
import Sketch from "react-p5";
import {Row} from 'react-bootstrap'
let values = [];
let resetSwitch = false;
async function sleep(ms){
  await new Promise(r => setTimeout(r, ms));
}
async function mergeSort(arr, left, right){
  await sleep(1);
  if(left < right){
    let middle = Math.floor(left + (right - left ) / 2);
    //Promise.all([mergeSort(arr, left, middle),mergeSort(arr, middle + 1, right)]);
    let m = async () =>{
      await mergeSort(arr, left, middle);
      await mergeSort(arr, middle + 1, right);
      await merge(arr, left, middle, right);
    }
    if(!resetSwitch){
      await m();
    }
    else{
      Promise.resolve(m);
      return
    }
  }
  return
}
async function merge(arr, left, middle, right){
  let lsize = middle - left + 1;
  let rsize  = right - middle;
  let leftArr = [];
  let rightArr = [];
  
  for(let i = 0; i < lsize; i++){
    leftArr[i] = arr[left + i];
  }
  for(let i = 0; i < rsize; i++){
    rightArr[i] = arr[middle + 1 + i];
  }
  let i = 0;
  let j = 0;
  let k = left;
  
  while(i < lsize && j < rsize){
    if(leftArr[i] <= rightArr[j]){
      arr[k] = leftArr[i];
      i++;
    }
    else{
      arr[k] = rightArr[j];
      j++;
    }
    k++;
  }
  while(i < lsize){
    arr[k] = leftArr[i];
    i++;
    k++;
  }
  while(j < rsize){
    arr[k] = rightArr[j];
    j++;
    k++;
  }
}
function MergeSort() {
  function makeValues(p5){
    for(let i = 0; i < p5.width; i++){
      let n = p5.random(p5.height);
      values.push(n);
    }
  }
  function changeValues(p5){
    for(let i = 0; i < p5.width; i++){
      values[i] = p5.random(p5.height);
    }
  }
  let setup = (p5, canvasParentRef) => {
    console.log("setup")
    let xyz = p5.createCanvas(500, 200 ).parent(canvasParentRef);
    xyz.position((p5.windowWidth-p5.width)/6, (p5.windowHeight- 2 * p5.height));
    if(values.length === 0){makeValues(p5)};
    let button = p5.createButton('Start');
    button.position((p5.windowWidth-p5.width)/6 , (p5.windowHeight- 2 * p5.height) - 30);
    button.style('background-color', p5.color(112, 113, 128))
    button.style('border-radius', "12px");
    button.mousePressed(() => {
      p5.loop();
      resetSwitch = false;
      mergeSort(values, 0, values.length - 1);
    });
    let reset = p5.createButton('Reset');
    reset.position((p5.windowWidth-p5.width)/6 + p5.width- reset.width, (p5.windowHeight- 2 * p5.height) - 30);
    reset.style('background-color', p5.color(112, 113, 128))
    reset.style('border-radius', "12px");
    reset.mousePressed(()=> {
      resetSwitch = true
      changeValues(p5);
      p5.noLoop();
    });
    p5.noLoop();
  };
  let draw = async (p5) => { 
    p5.background(0);
    for(let i = 0; i < values.length; i++){
      p5.stroke(222);
      p5.line(i, p5.height, i, p5.height-values[i])
    };
  };  
  return (
    <div className="MergeSort">
      <Sketch setup={setup} draw={draw} className="MergeSort" />
    </div>
  );
}

export default MergeSort;
