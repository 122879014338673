import React from 'react';
import BubbleSortPage from './BubbleSortPage';
import SelectionSortPage from './SelectionSortPage';
import QuickSortPage from './QuickSortPage';
import Header from './Header'
import Home from './Home';
import MergeSortPage from './MergeSortPage';
import {Row} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom'
function App() {
  return(
    <div className = "App">
      <Row>
        <Header/>
      </Row>
      <Routes>
        <Route path = "/bubblesort" element={<BubbleSortPage />}/>
        <Route exact path = "/" element={<Home />}/>
        <Route path = "/selectionsort" element={<SelectionSortPage />}/>
        <Route path = "/quicksort" element={<QuickSortPage />}/>
        <Route path = "/mergesort" element={<MergeSortPage />}/>
      </Routes>
    </div>
  )
}

export default App;
