import React from 'react';
import Sketch from "react-p5";
let values = [];
let j = 0;
let swaps = 0;
let swapped = false;
function BubbleSort() {
  function makeValues(p5){
    for(let i = 0; i < p5.width/100; i+= (1/100)){
      let n = p5.noise(i);
      n = p5.map(n, 0, 1, 0, p5.height);
      values.push(n);
    }
  }
  function changeValues(p5){
    for(let i = 0; i < p5.width/100; i+= (1/100)){
      let n = p5.noise(i);
      n = p5.map(n, 0, 1, 0, p5.height);
      console.log(Math.round(i*100))
      values[Math.round(i*100)] = n
    }
  }
  let setup = (p5, canvasParentRef) => {
    let xyz = p5.createCanvas(500, 200).parent(canvasParentRef);
    xyz.position((p5.windowWidth-p5.width)/6, (p5.windowHeight- 2 * p5.height));
    if(values.length === 0){makeValues(p5)};
    let reset = p5.createButton('Reset');
    reset.position((p5.windowWidth-p5.width)/6 + p5.width- reset.width, (p5.windowHeight- 2 * p5.height) - 30);
    reset.style('background-color', p5.color(112, 113, 128))
    reset.style('border-radius', "12px");
    reset.mousePressed(()=> {
      p5.noLoop();
      j = -1;
      changeValues(p5);
      p5.redraw();
    });
    let button = p5.createButton('Start');
    button.position((p5.windowWidth-p5.width)/6 , (p5.windowHeight- 2 * p5.height) - 30);
    button.style('background-color', p5.color(112, 113, 128))
    button.style('border-radius', "12px");
    button.mousePressed(() => p5.loop());
    p5.noLoop();
  };
  let draw = (p5) => {
    p5.background(0);
    for(let i = 0; i < 100; i++){
      if(j < values.length - 1){
        let a = values[j];
        let b = values[j + 1];
        if(a > b){
          let temp = values[j];
          values[j] = values[j + 1];
          values[j + 1] = temp
          swaps += 1
          swapped = true;
        }
        j++
      }
      else{
        if(swaps === 0) p5.noLoop();
        else{
          j = 0;
          swaps = 0;
        }
      }
    }
    for(let i = 0; i < values.length; i++){
      if(swapped && i === j - 1){
        p5.stroke(0, 255, 0);
      }
      else if(i === j -1){
        p5.stroke(255, 0, 0);
      }
      else {
        p5.stroke(222)
      }
      p5.line(i, p5.height, i, p5.height-values[i]);
    };
    swapped = false;
  };  
  return (
    <div className="BubbleSort">
      <Sketch setup={setup} draw={draw} className="BubbleSort" />
    </div>
  );
}

export default BubbleSort;
