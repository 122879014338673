import React from 'react';
import Sketch from "react-p5";
let values = [];
let j = 0;
let swaps = 0;
let swapped = false;
let width = 10;

function BubbleSortColor() {
    function makeValues(p5){
      for(let i = 0; i < p5.width/width; i++){
        let n = p5.random(255);
        values.push(n);
      }
    }
    function changeValues(p5){
      for(let i = 0; i < p5.width/width; i++){
        values[i] = p5.random(255);
      }
    }
  let setup = (p5, canvasParentRef) => {
    p5.background(0);
    let xyz = p5.createCanvas(500, 200).parent(canvasParentRef);
    xyz.position((p5.windowWidth)/6 + p5.width, (p5.windowHeight- 2 * p5.height));
    if(values.length === 0){makeValues(p5)};
    let reset = p5.createButton('Reset');
    reset.position((p5.windowWidth)/6 + p5.width * 2 - reset.width, (p5.windowHeight- 2 * p5.height) - 30);
    reset.style('background-color', p5.color(112, 113, 128))
    reset.style('border-radius', "12px");
    reset.mousePressed(()=> {
      p5.noLoop();
      j = 0;
      changeValues(p5);
      p5.redraw();
    });
    let button = p5.createButton('Start');
    button.position((p5.windowWidth)/6 + p5.width, (p5.windowHeight- 2 * p5.height) - 30);
    button.style('background-color', p5.color(112, 113, 128))
    button.style('border-radius', "12px");
    button.mousePressed(() => p5.loop());
    p5.noLoop();
  };
  let draw = (p5) => {
    if(j < values.length - 1){
      let a = values[j];
      let b = values[j + 1];
      if(a > b){
        let temp = values[j];
        values[j] = values[j + 1];
        values[j + 1] = temp;
        swaps += 1;
        swapped = true;
      }
      j++
    }
    else{
      if(swaps === 0) p5.noLoop();
      else{
        j = 0;
        swaps = 0;
      }
    }
    for(let i = 0; i < values.length; i++){
      // if(i === j - 1){
      //   //p5.fill(0, 255, 0)
      // }
      // else if(i === j){
      //  // p5.fill(255, 0, 0)
      // }
      if(swapped && i === j - 1){
        p5.fill(0, 255, 0);
      }
      else if(i === j -1){
        p5.fill(255, 0, 0);
      }
      else {
        p5.fill(values[i]);
      }
      p5.rect(i*width, 0, width, p5.height);
    };
    swapped = false
  };
  return (
    <div className="BubbleSortColor">
      <Sketch setup={setup} draw={draw} className="BubbleSortColor" />
    </div>
  );
}

export default BubbleSortColor;
