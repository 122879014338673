import React from 'react';
import {Nav, Container, Navbar} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import './Header.css';


function Header() {
  const navigate  = useNavigate();
  return(
    <div className = "Header">
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home" >Sorting Algorithms!</Navbar.Brand>
          <Nav className="justify-content-end">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link  onClick={()=> navigate("/bubblesort")}>Bubble Sort</Nav.Link>
            <Nav.Link onClick={()=> navigate("/selectionsort")}>Selection Sort</Nav.Link>
            <Nav.Link onClick={()=> navigate("/quicksort")}>QuickSort</Nav.Link>
            <Nav.Link onClick={()=> navigate("/mergesort")}>Merge Sort</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header;
