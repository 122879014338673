import React from 'react';
import Sketch from "react-p5";
let values = [];
let width = 2;
let states = [];
let resetSwitch = false;
async function sleep(ms){
  await new Promise(r => setTimeout(r, ms));
}
async function swap(arr, a, b){
  let temp = arr[a]; 
  arr[a] = arr[b]
  arr[b] = temp
}
async function quicksort(arr, start, end, p5){
  p5.redraw();
  await sleep(500);
  if(start >= end){
    return
  }
  let index = await partition(arr, start, end);
  states[index] = -1;
  let p = async () => Promise.all([quicksort(arr, start, index - 1, p5), quicksort(arr, index + 1, end, p5)])
  if(!resetSwitch){
    await p();
  }
  else{
    Promise.resolve(p);
    return
  }
    
  }
async function partition(arr, start, end){
  for(let i = start; i < end; i++){
    states[i] = 1;
  }
  let pivotIndex = start
  let pivotValue = arr[end];
  states[pivotIndex] = 0;
  for(let j = start; j < end; j++){
    if(arr[j] < pivotValue){
      await swap(arr, pivotIndex, j);
      states[pivotIndex] = -1;
      pivotIndex++
      states[pivotIndex] = 0;
    }
  }
  await swap(arr, pivotIndex, end);
  return pivotIndex;
}
function QuickSortColor() {
  function makeValues(p5){
    for(let i = 0; i < p5.width/width; i++){
      let n = p5.random(255);
      values.push(n);
      states.push(-1);
    } 
  }
  function changeValues(p5){
    for(let i = 0; i < p5.width/width; i++){
      let n = p5.random(255);
      values[i] = n;
      states[i] = -1;
    } 
  }
  let setup = (p5, canvasParentRef) => {
    let xyz = p5.createCanvas(500, 200 ).parent(canvasParentRef);
    xyz.position((p5.windowWidth)/6 + p5.width, (p5.windowHeight- 2 * p5.height));
    if(values.length === 0){makeValues(p5)};
    let button = p5.createButton('Start');
    button.position((p5.windowWidth)/6 + p5.width, (p5.windowHeight- 2 * p5.height) - 30);
    button.style('background-color', p5.color(112, 113, 128))
    button.style('border-radius', "12px");
    button.mousePressed(() => {
      resetSwitch = false;
      quicksort(values, 0, values.length - 1, p5);
    }); 
    let reset = p5.createButton('Reset');
    reset.position((p5.windowWidth)/6 + p5.width * 2 - reset.width, (p5.windowHeight- 2 * p5.height) - 30);
    reset.style('background-color', p5.color(112, 113, 128))
    reset.style('border-radius', "12px");
    reset.mousePressed(()=> {
      resetSwitch = true;
      p5.noLoop();
      changeValues(p5);
      p5.redraw();
    });
  };
  let draw = (p5) => { 
    //p5.background(0);
    for(let i = 0; i < values.length; i++){
      if(states[i] === 1){
        p5.fill(0, 255, 0);
      }
      else{
        p5.fill(values[i]);
      }
      p5.rect(i*width, 0, width, p5.height)
    };
  };  
  return (
    <div className="QuickSortColor">
      <Sketch setup={setup} draw={draw} className="QuickSortColor" />
    </div>
  );
}

export default QuickSortColor;
