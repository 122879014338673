import React from 'react';
import BubbleSort from './BubbleSort';
import BubbleSortColor from './BubbleSortColor';
import {Row} from 'react-bootstrap'
function BubbleSortPage() {
  return(
    <div className = "BubbleSortPage">
      <Row className = 'text-center'>
        <h1>Bubble Sort</h1>
        <p>The left box is sorting a field of lines created with perlin noise. It sorts them based on the height of the line. This process
          is sped up 100% for visualization purposes</p>
        <p> The right box is sorting rectangles based on their color, with light rectangles having a higher values than dark rectangles.</p>
      </Row>
      <BubbleSort />
      <BubbleSortColor />
    </div>
  )
}

export default BubbleSortPage;
