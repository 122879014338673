import React from 'react';
import MergeSort from './MergeSort';
import MergeSortColor from './MergeSortColor';
import {Row} from 'react-bootstrap'
function MergeSortPage() {
  return(
    <div className = "MergeSortPage">
      <Row className = 'text-center'>
        <h1>MergeSort</h1>
        <p>The left box is sorting a field of lines created with random lengths. It sorts them based on the height of the line. This process is slowed
          down for visualization purposes.
        </p>
        <p> The right box is sorting rectangles based on their color, with light rectangles having a higher values than dark rectangles. This
          process is also slowed down.
        </p>
      </Row>
      <MergeSort />
      <MergeSortColor />
    </div>
  )
}

export default MergeSortPage;
