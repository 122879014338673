import React from 'react';
import Sketch from "react-p5";
let values = [];
let states = [];
let resetSwitch = false;
async function sleep(ms){
  await new Promise(r => setTimeout(r, ms));
}
async function swap(arr, a, b){
   let temp = arr[a]; 
   arr[a] = arr[b]
   arr[b] = temp
 }
async function quicksort(arr, start, end, p5){
  p5.redraw();
  await sleep(500);
  if(start >= end){
    return
  }
  let index = await partition(arr, start, end, p5);
  states[index] = -1;
  let p = async () => Promise.all([quicksort(arr, start, index - 1, p5), quicksort(arr, index + 1, end, p5)])
  if(!resetSwitch){
    await p();
  }
  else{
    Promise.resolve(p);
    return
  }
    
  }
async function partition(arr, start, end, p5){
  for(let i = start; i < end; i++){
    states[i] = 1;
  }
  let pivotIndex = start
  let pivotValue = arr[end];
  states[pivotIndex] = 0;
  for(let j = start; j < end; j++){
    if(arr[j] < pivotValue){
      await swap(arr, pivotIndex, j);
      states[pivotIndex] = -1;
      pivotIndex++
      states[pivotIndex] = 0;
    }
  }
  await swap(arr, pivotIndex, end);
  return pivotIndex;
}
function QuickSort() {
  function makeValues(p5){
    for(let i = 0; i < p5.width; i++){
      let n = p5.random(p5.height);
      values.push(n);
      states.push(-1);
    }
  }
  function changeValues(p5){
    for(let i = 0; i < p5.width; i++){
      values[i] = p5.random(p5.height);
      states[i] = -1;
    }
  }
  let setup = (p5, canvasParentRef) => {
    let xyz = p5.createCanvas(500, 200 ).parent(canvasParentRef);
    xyz.position((p5.windowWidth-p5.width)/6, (p5.windowHeight- 2 * p5.height));
    if(values.length === 0){makeValues(p5)};
    let button = p5.createButton('Start');
    button.position((p5.windowWidth-p5.width)/6 , (p5.windowHeight- 2 * p5.height) - 30);
    button.style('background-color', p5.color(112, 113, 128))
    button.style('border-radius', "12px");
    button.mousePressed(() => {
      console.log(values)
      resetSwitch = false;
      quicksort(values, 0, values.length - 1, p5)
    });
    let reset = p5.createButton('Reset');
    reset.position((p5.windowWidth-p5.width)/6 + p5.width- reset.width, (p5.windowHeight- 2 * p5.height) - 30);
    reset.style('background-color', p5.color(112, 113, 128))
    reset.style('border-radius', "12px");
    reset.mousePressed(()=> {
      resetSwitch = true
      p5.noLoop();
      changeValues(p5);
      p5.redraw();
    });
    p5.noLoop();
  };
  let draw = async (p5) => { 
    p5.background(0);
    for(let i = 0; i < values.length; i++){
      if(states[i] === 0){
        p5.stroke(255, 0, 0);
      }
      else if(states[i] === 1){
        p5.stroke(0, 255, 0);
      }
      else{
        p5.stroke(222);
      }
      p5.line(i, p5.height, i, p5.height-values[i])
    };
  };  
  return (
    <div className="QuickSort">
      <Sketch setup={setup} draw={draw} className="QuickSort" />
    </div>
  );
}

export default QuickSort;
