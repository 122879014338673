import React from 'react';
import Sketch from "react-p5";
let values = [];

function SelectionSort() {
  function swap(arr, a, b){
    let temp = arr[a]; 
    arr[a] = arr[b]
    arr[b] = temp
  }
  function makeValues(p5){
    for(let i = 0; i < p5.width/100; i+= (1/100)){
      let n = p5.noise(i);
      n = p5.map(n, 0, 1, 0, p5.height);
      values.push(n);
    }
  }
  function changeValues(p5){
    for(let i = 0; i < p5.width/100; i+= (1/100)){
      let n = p5.noise(i);
      n = p5.map(n, 0, 1, 0, p5.height);

      values[Math.round(i*100)] = n
    }
  }
  let setup = (p5, canvasParentRef) => {
    p5.background(0);
    let xyz = p5.createCanvas(500, 200).parent(canvasParentRef);
    xyz.position((p5.windowWidth-p5.width)/6, (p5.windowHeight- 2 * p5.height));
    if(values.length === 0){makeValues(p5)};
    let reset = p5.createButton('Reset');
    reset.position((p5.windowWidth-p5.width)/6 + p5.width- reset.width, (p5.windowHeight- 2 * p5.height) - 30);
    reset.style('background-color', p5.color(112, 113, 128))
    reset.style('border-radius', "12px");
    reset.mousePressed(()=> {
      p5.noLoop();
      i = -1;
      min = 0;
      changeValues(p5);
      p5.redraw();
    });
    let button = p5.createButton('Start');
    button.position((p5.windowWidth-p5.width)/6 , (p5.windowHeight- 2 * p5.height) - 30);
    button.style('background-color', p5.color(112, 113, 128))
    button.style('border-radius', "12px");
    button.mousePressed(() => p5.loop());
    p5.noLoop();
  };
  let i = 0;
  let min = 0;
  let draw = (p5) => {
    p5.background(0);
    for(let n = 0; n < values.length; n++){
      if(n == i){
        p5.stroke(0, 255, 0);
      }
      else{p5.stroke(222)}
      p5.line(n, p5.height, n, p5.height - values[n]);
    };
    if(i < values.length){
      for(let j = i; j < values.length; j++){
        if(values[j] < values[min]){
          min = j
        }
      }
      p5.stroke(255, 0, 0);
      p5.line(min, p5.height, min, p5.height - values[min])
      swap(values, min, i); 
      i++
      min = i
    }
    else{p5.noLoop();}
  };  
  return (
    <div className="SelectionSort">
      <Sketch setup={setup} draw={draw} className="SelectionSort" />
    </div>
  );
}

export default SelectionSort;
