import React from 'react';
import {Row, Col} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import './home.css'

function Home() {
  const navigate = useNavigate();
  return(
    <div className = "App">
     <Row className = "text-center">
       <h1>Sorting Algorithm Visualizations</h1>
     </Row>
      <Col>
       <Row>
        <div className = 'pt-5 d-flex flex-row'>
          <button className ="button-84 m-3" onClick={()=> navigate("/bubblesort")}>Bubble Sort</button>
          <b className = "mt-3">Bubble sort</b>
          <p className = "mt-3"> - A sorting algorithm in which neighboring two values are compared and swapped into right order if necessary in the most inner loop. </p>
        </div>
       </Row>
       <Row>
       <div className = 'mr-3 d-flex flex-row'>
      <button className ="button-84 m-3 " onClick={()=> navigate("/selectionsort")}>Selection Sort</button>
          <b className = "mt-3">Selection Sort</b>
          <p className = "mt-3"> -  Sorts an array by repeatedly finding the minimum element (considering ascending order) from unsorted part and putting it at the beginning. </p>
        </div>
       </Row>
       <Row>
       <div className = 'mr-3 d-flex flex-row'>
          <button className ="button-84 m-3 " onClick={()=> navigate("/quicksort")}>QuickSort</button>
          <b className = "mt-3">Quick Sort</b>
          <p className = "mt-3"> - A divide and conquer algorithm that uses chosen pivots to keep dividing the list into two until there is only one item on each side of the pivot left. </p>
        </div>
       </Row>
       <Row>
       <div className = 'mr-3 d-flex flex-row'>
          <button className ="button-84 m-3" onClick={()=> navigate("/mergesort")}>Merge Sort</button>
          <b className = "mt-3">Merge Sort</b>
          <p className = "mt-3"> - A divide and conquer algorithm that divides the input array into two halves, calls itself for the two halves, and then merges the two sorted halvess. </p>
        </div>
       </Row>
      </Col>
     </div>
  )
}

export default Home;
