import React from 'react';
import Sketch from "react-p5";
let values = [];
let i = 0;
let min = 0;
let width = 2;

function SelectionSortColor() {
  function makeValues(p5){
    for(let i = 0; i < p5.width/width; i++){
      let n = p5.random(255);
      values.push(n);
    }
  }
  function changeValues(p5){
    for(let i = 0; i < p5.width/width; i++){
      values[i] = p5.random(255);
    }
  }
  let setup = (p5, canvasParentRef) => {
    p5.background(0);
    let xyz = p5.createCanvas(500, 200).parent(canvasParentRef);
    xyz.position((p5.windowWidth)/6 + p5.width, (p5.windowHeight- 2 * p5.height));
    if(values.length === 0){makeValues(p5)};
    let reset = p5.createButton('Reset');
    reset.position((p5.windowWidth)/6 + p5.width * 2 - reset.width, (p5.windowHeight- 2 * p5.height) - 30);
    reset.style('background-color', p5.color(112, 113, 128))
    reset.style('border-radius', "12px");
    reset.mousePressed(()=> {
      p5.noLoop();
      i = 0;
      min = 0;
      changeValues(p5);
      p5.redraw();
    });
    let button = p5.createButton('Start');
    button.position((p5.windowWidth)/6 + p5.width, (p5.windowHeight- 2 * p5.height) - 30);
    button.style('background-color', p5.color(112, 113, 128))
    button.style('border-radius', "12px");
    button.mousePressed(() => p5.loop());
    p5.noLoop();
  };
  function swap(arr, a, b){
    let temp = arr[a]; 
    arr[a] = arr[b]
    arr[b] = temp
  }
  let draw =  (p5) => {
    for(let n = 0; n < values.length; n++){
      if(n == i){
        p5.fill(0, 255, 0);
      }
      else{
        try {
          p5.fill(values[n])
        } catch (error) {
          console.log(values.length)
        }
    
      }
      p5.rect(n*width, 0, width, p5.height);
    };
    if(i < values.length){
      for(let j = i; j < values.length; j++){
        if(values[j] < values[min]){
          min = j
        }
      }
      p5.fill(255, 0, 0);
      p5.rect(min*width, 0, width, p5.height);
      swap(values, min, i); 
      i++
      min = i
    }
    else{p5.noLoop();}
  };  
  return (
    <div className="SelectionSortColor">
      <Sketch setup={setup} draw={draw} className="SelectionSortColor" />
    </div>
  );
}

export default SelectionSortColor;
