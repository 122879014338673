import React from 'react';
import SelectionSort from './SelectionSort';
import SelectionSortColor from './SelectionSortColor';
import {Row} from 'react-bootstrap'
function SelectionSortPage() {
  return(
    <div className = "SelectionSortPage">
      <Row className = 'text-center'>
        <h1>Selection Sort</h1>
        <p>The left box is sorting a field of lines created with perlin noise. It sorts them based on the height of the lines</p>
        <p> The right box is sorting rectangles based on their color, with light rectangles having a higher values than dark rectangles.</p>
      </Row>
      <SelectionSort />
      <SelectionSortColor />
    </div>
  )
}

export default SelectionSortPage;
